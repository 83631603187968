<template>
  <v-container>
    <v-row class="text-center" dense justify="center">
      <v-col cols="12" lg="10" xl="8" >
        <v-img
            src="img/kaesecaspar-titel.jpg"
            class="mb-5"
            fill
            position="center top"
        />
      </v-col>

      <v-col cols="12" lg="10" xl="8" class="mb-5">
          <v-toolbar id="toolbar" flat style="z-index: 99999" color="transparent">
            <v-btn icon class="hidden-md-and-up" @click="toggleMenu = !toggleMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-menu
                v-model="showMenu"
                :absolute="toggleMenu"
                :attach="'#toolbar'"
                :content-class="'elevation-0'"
                :nudge-top="$vuetify.breakpoint.smAndDown?-52:-12"
                min-width="100%"
                style="z-index: 1000;"
                :close-on-click="$vuetify.breakpoint.smAndDown ? true : false"
                :close-on-content-click="$vuetify.breakpoint.smAndDown ? true : false"
                @input="menuEvent"
            >
              <div class="d-flex flex-column flex-md-row justify-center" :style="$vuetify.breakpoint.smAndDown ? 'background: white; padding: 20px;' : ''">
                <template v-for="item in menu">
                  <v-btn v-if="item.icon == ''" text :key="item.title" :href="item.link">{{ item.title }}</v-btn>
                  <v-btn v-if="item.icon !== ''" text :key="item.title" :href="item.link"><v-icon>{{ item.icon }}</v-icon></v-btn>
                </template>

              </div>
            </v-menu>
          </v-toolbar>
      </v-col>

      <v-col cols="12" lg="10" xl="8" class="mb-3">
        <h1 class="display-1 font-weight-bold mb-5 text-uppercase">
          {{ settings.main_title }}
        </h1>
        <p>
          {{ settings.main_description }}
        </p>
      </v-col>
      <v-col cols="12" lg="10" xl="8">
        <v-expansion-panels
            active-class="expanded"
            flat
        >
          <v-expansion-panel style="background-color: transparent"
          >
            <v-expansion-panel-header
                class="content"
                color="transparent"
                expand-icon="mdi-information-variant"
            >
              <div class="heading text-center font-weight-bold ">
                {{ settings.main_info_title }}
<!--                <v-icon left>mdi-information-outline</v-icon>Käse in Vorarlberg-->
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="settings.main_info">
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="text-center" dense justify="center">
      <v-col cols="12" lg="10" xl="8">
        <products></products>
      </v-col>
    </v-row>
    <v-row class="text-center pb-10"  justify="center">
      <v-col v-if="basket.length > 0" cols="12" lg="5" xl="4">
        <v-btn block class="pa-2 text--primary" color="secondary"  large :disabled="basket.length <= 0" to="anfrage">{{ basket.length }} Produkt(e) im Warenkorb<v-icon right>mdi-arrow-right</v-icon></v-btn>
      </v-col>
<!--      <v-col  cols="12" lg="5" xl="4">-->
<!--        <v-btn color="primary" dark large block class="pa-2" href="tel:+4369918384320"><v-icon left>mdi-phone</v-icon>Telefonische Bestellung</v-btn>-->
<!--      </v-col>-->
    </v-row>
<!--    <v-row class="mb-10" justify="center" align="center">-->
<!--      <v-col align="center">-->
<!--        <v-btn small color="secondary" class="text&#45;&#45;primary" href="https://www.kaesecaspar.at">-->
<!--          <v-icon left>mdi-home</v-icon>Zurück zur Homepage</v-btn>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
  import Products from "./Products";
  export default {
    name: 'HelloWorld',
    components: {Products},
    data: () => ({
      toggleMenu: false,
      menu: [
        { link: "https://www.kaesecaspar.at/verkauf", title: "Verkauf", icon: "" },
        { link: "https://www.kaesecaspar.at/catering", title: "Catering", icon: "" },
        { link: "https://www.kaesecaspar.at/promotion-und-schulung", title: "Promotion & Schulung", icon: "" },
        { link: "https://www.kaesecaspar.at/gallery", title: "Galerie", icon: "" },
        { link: "https://www.kaesecaspar.at/kontakt", title: "Kontakt", icon: "" },
        { link: "https://www.facebook.com/derkaesecaspar", title: "", icon: "mdi-facebook" }
      ]
    }),
    computed: {
      ...mapState({
        products: state => state.products,
        settings: state => state.settings,
        basket: state => state.basket
      }),
      showMenu() {
        return this.toggleMenu || this.$vuetify.breakpoint.mdAndUp
      }
    },
    methods: {
      menuEvent (arg) {
        console.log(arg)
        if (arg === false) {
          this.toggleMenu = false
        }
      }
    }
  }
</script>

<style>
.v-expansion-panel--active>.v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  /* transform: rotate(-180deg); */
  transform: scale(1.2);
  color: black;
  /* outline: green 1px solid; */
  border-radius: 100px;
  border: black 1px solid;
  /* padding-right: 10px; */
  margin-right: 10px;
}
</style>
