import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                'app-bar': colors.blue.base,
                primary: '#000000',
                secondary: '#FFDB15',

                primaryText: colors.green.accent1,
                textPrimary: '#ff00ff',
                background: '#eaeaea',
                defaultTextColor: colors.green.darken1
            },
            dark: {
                // background: colors.pink.base,
                // mycolor: colors.purple.base
                // secondary: colors.blue.base,
                // primaryText: colors.green.accent1,
                // secondaryText: colors.green.base
            }
        },
        options: {
            customProperties: true
        }
    }
})
