import Vue from 'vue'
import Vuex from 'vuex'
import * as process from 'process'
import axios from 'axios'

Vue.use(Vuex)

const baseUrl = process.env.NODE_ENV === 'production' ? 'http://kaesecaspar.limemotion.com/' : '/'

export default new Vuex.Store({
  state: {
    settings: {},
    categories: [],
    products: [
      {
        id: 1,
        category: 'Hartkäse',
        image: '0a-Casp_A-in-A_IMG_9306-Entwurf-1_3.4.2020_MG.jpg',
        title: 'Bregenzerwälder Hanfsamen Käse',
        teaser: 'Hanfsamenkäse, ein echter Vorarlberger.',
        description: 'Hanfsamenkäse, ein echter Vorarlberger.<br>Eine duftig Kooperation mit den  Hempions, ein junges Startup mit dem Gebrüdern Fabian und Florian Braitsch, Rohmilchsenner Markus Faisst von der Hofsennerei Engel und Affineur Käsecaspar.<br><br>Tagfrische Rohmilch wird sorgfältig zu einem vollfetten Schnittkäse verarbeitet. Die gerösteten Hanfsamen kommen beim Abfüllen unter Schwimmübungen in den Käseteig.<br>Geschmack von Hanf ist am ehesten leicht nussig. Die zarten Röstaromen harmonieren hervorragend mit dem cremig, vollmundigen Käse. Der knackige Abgang ist putz varruckt.',
        manufacturer: 'Käsecaspar',
        price: "€ 5,00 pro 100g",
        item: "ca. 380g"
      },
      {
        id: 2,
        category: 'Hartkäse',
        image: '0a-Casp_A-in-A_IMG_9306-Entwurf-1_3.4.2020_MG.jpg',
        title: 'Bregenzerwälder Hanfsamen Käse',
        teaser: 'Hanfsamenkäse, ein echter Vorarlberger.',
        description: 'Hanfsamenkäse, ein echter Vorarlberger.<br>Eine duftig Kooperation mit den  Hempions, ein junges Startup mit dem Gebrüdern Fabian und Florian Braitsch, Rohmilchsenner Markus Faisst von der Hofsennerei Engel und Affineur Käsecaspar.<br><br>Tagfrische Rohmilch wird sorgfältig zu einem vollfetten Schnittkäse verarbeitet. Die gerösteten Hanfsamen kommen beim Abfüllen unter Schwimmübungen in den Käseteig.<br>Geschmack von Hanf ist am ehesten leicht nussig. Die zarten Röstaromen harmonieren hervorragend mit dem cremig, vollmundigen Käse. Der knackige Abgang ist putz varruckt.',
        manufacturer: 'Käsecaspar',
        price: "€10,00 pro 100g",
        item: "ca. 380g"
      },
      {
        id: 3,
        category: 'Hartkäse',
        image: '0a-Casp_A-in-A_IMG_9306-Entwurf-1_3.4.2020_MG.jpg',
        title: 'Bregenzerwälder Hanfsamen Käse',
        teaser: 'Hanfsamenkäse, ein echter Vorarlberger.',
        description: 'Hanfsamenkäse, ein echter Vorarlberger.<br>Eine duftig Kooperation mit den  Hempions, ein junges Startup mit dem Gebrüdern Fabian und Florian Braitsch, Rohmilchsenner Markus Faisst von der Hofsennerei Engel und Affineur Käsecaspar.<br><br>Tagfrische Rohmilch wird sorgfältig zu einem vollfetten Schnittkäse verarbeitet. Die gerösteten Hanfsamen kommen beim Abfüllen unter Schwimmübungen in den Käseteig.<br>Geschmack von Hanf ist am ehesten leicht nussig. Die zarten Röstaromen harmonieren hervorragend mit dem cremig, vollmundigen Käse. Der knackige Abgang ist putz varruckt.',
        manufacturer: 'Käsecaspar',
        price: "€10,00 pro 100g",
        item: "ca. 380g"
      },
      {
        id: 4,
        category: 'Hartkäse',
        image: '0a-Casp_A-in-A_IMG_9306-Entwurf-1_3.4.2020_MG.jpg',
        title: 'Bregenzerwälder Hanfsamen Käse',
        teaser: 'Hanfsamenkäse, ein echter Vorarlberger.',
        description: 'Hanfsamenkäse, ein echter Vorarlberger.<br>Eine duftig Kooperation mit den  Hempions, ein junges Startup mit dem Gebrüdern Fabian und Florian Braitsch, Rohmilchsenner Markus Faisst von der Hofsennerei Engel und Affineur Käsecaspar.<br><br>Tagfrische Rohmilch wird sorgfältig zu einem vollfetten Schnittkäse verarbeitet. Die gerösteten Hanfsamen kommen beim Abfüllen unter Schwimmübungen in den Käseteig.<br>Geschmack von Hanf ist am ehesten leicht nussig. Die zarten Röstaromen harmonieren hervorragend mit dem cremig, vollmundigen Käse. Der knackige Abgang ist putz varruckt.',
        manufacturer: 'Käsecaspar',
        price: "€10,00 pro 100g",
        item: "ca. 380g"
      }
    ],
    pproducts: [
      {
        id: 1,
        category: 'Hartkäse',
        image: '0a-Casp_A-in-A_IMG_9306-Entwurf-1_3.4.2020_MG.jpg',
        title: 'Bregenzerwälder Hanfsamen Käse',
        teaser: 'Hanfsamenkäse, ein echter Vorarlberger.',
        description: 'Hanfsamenkäse, ein echter Vorarlberger.<br>Eine duftig Kooperation mit den  Hempions, ein junges Startup mit dem Gebrüdern Fabian und Florian Braitsch, Rohmilchsenner Markus Faisst von der Hofsennerei Engel und Affineur Käsecaspar.<br><br>Tagfrische Rohmilch wird sorgfältig zu einem vollfetten Schnittkäse verarbeitet. Die gerösteten Hanfsamen kommen beim Abfüllen unter Schwimmübungen in den Käseteig.<br>Geschmack von Hanf ist am ehesten leicht nussig. Die zarten Röstaromen harmonieren hervorragend mit dem cremig, vollmundigen Käse. Der knackige Abgang ist putz varruckt.',
        manufacturer: 'Käsecaspar',
        price: 239
      },
      {
        id: 2,
        category: 'Hartkäse',
        image: '1-Casp_IMG_4849_Entwurf-2_kaesecaspar_1-2020_MG-18.jpg',
        title: 'Bregenzerwälder Alpenkäse Reserve',
        teaser: 'Alpkäse Sommer 2020 von der Alpe Schönenbach.Alpkäse Sommer 2020 von der Alpe Schönenbach.Alpkäse Sommer 2020 von der Alpe Schönenbach.Alpkäse Sommer 2020 von der Alpe Schönenbach.Alpkäse Sommer 2020 von der Alpe Schönenbach.Alpkäse Sommer 2020 von der Alpe Schönenbach.',
        description: 'Das Beste vom Besten! Bergkäse, über 2 Jahre gereift. Ca. 500 Gramm pro Käsestück. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 249
      },
      {
        id: 3,
        category: 'Hartkäse',
        image: '3-Casp_zA-in-A_IMG_5754-in1630_Entwurf-2_3.4.2020_MG.jpg',
        title: 'Emmentaler',
        teaser: 'Emmentaler ist ein Hartkäse mit 45 % Fett i.Tr.',
        description: 'Emmentaler ist ein Hartkäse mit 45 % Fett i.Tr. Emmentaler ist bekannt für seine großen Löcher. Der Teig des Emmentaler ist elfenbeinfarben bis gelb, das Aroma würzig bis nussig. In Deutschland wird Emmentaler auch gelegentlich als Schweizer Käse bezeichnet. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 219
      },
      {
        id: 4,
        category: 'Hartkäse',
        image: '1-Casp_KC-15A_IMG_8250_kaesecaspar_1-2020_MG-15.jpg',
        title: 'Cheddar',
        teaser: 'Feinster Cheddar Käse vom Käsecaspar.',
        description: 'Die Ursprünge von Cheddar Käse reichen ins 12. Jahrhundert und in das Dorf Cheddar in der englischen Grafschaft Somerset zurück. Je nach Reifezeit (bis zu 2 Jahren, mindestens jedoch 1 bis 2 Monate) zählt der Kuhmilchkäse als Schnittkäse oder Hartkäse. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 299
      },
      {
        id: 5,
        category: 'Weichkäse',
        image: '2-Casp_C1-a-in-A_IMG_5754-in1630_Entwurf-2_3.4.2020_MG.jpg',
        title: 'BIO Briespritze 180g',
        teaser: 'Cremig mild in der ganzen Torte gereift.',
        description: 'Unser Bio-Brie cremt sich mild in die Herzen aller Genießer, die das Besondere zu schätzen wissen. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 209
      },
      {
        id: 6,
        category: 'Weichkäse',
        image: '2-Casp_C1-d2-in-A_IMG_5754-in1630_Entwurf-2_3.4.2020_MG.jpg',
        title: 'Cathedral City Cheddar',
        teaser: 'Wunderbar würzig und einzigartig im Geschmack.',
        description: 'Cathedral City Cheddar ist ein vielfach ausgezeichneter Premium Cheddar, der seit 50 Jahren nach der immer gleichen, traditionellen Rezeptur produziert wird. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 239
      },
      {
        id: 7,
        category: 'Weichkäse',
        image: '2-Casp_C2-a2-in-A_IMG_5760-in1630_Entwurf-2_3.4.2020_MG.jpg',
        title: 'BIO Edelschimmelkäse',
        teaser: 'Würzig-kräftiger Geschmack.',
        description: 'Der Bio-Schimmelkäse ist nichts für weiße Pferde, aber das Richtige für alle Genießer. Würzig-feines Aroma und eine unwiderstehliche Konsistenz machen ihn zum Star auf der Käseplatte. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 249
      },
      {
        id: 8,
        category: 'Weichkäse',
        image: '2-Casp_C3-a2-in-A_IMG_5796_20x20-vint_10-2017_MG25.8.2017_MG.jpg',
        title: 'Bergader Bavaria Blu Der Würzige',
        teaser: 'Bayerisches Käsehandwerk. 175g Packung',
        description: 'Von Natur aus laktosefrei und glutenfrei. Aromatisch-würziger Weichkäse mit hohem Blauschimmel-Anteil. Unser Käse wird ganz ohne Zusatzstoffe hergestellt. Der blaue Edelschimmel auf der Oberfläche ist ein Zeichen seiner Naturbelassenheit. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 279
      },
      {
        id: 9,
        category: 'Sonstiges',
        image: '2-Casp_IMG_8092-B2_kaesecaspar_1-2020_MG-1.jpg',
        title: 'Bregenzerwälder Fondue',
        teaser: 'Unser Bregenzerwälder Käsefondue.',
        description: 'Atemberaubender Geschmack aus 100% Heumilch Käse. Der perfekte Allrounder das ganze Jahr, für Käseliebhaber und Genießer. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 849
      },
      {
        id: 10,
        category: 'Sonstiges',
        image: '2-Casp_KC-18A2_IMG_8266_kaesecaspar_1-2020_MG-18.jpg',
        title: 'Bregenzerwälder Bergkäserollen',
        teaser: 'Für die schnelle, schmackhafte Küche.',
        description: 'Beste Zutaten mit würzigem Bregenzerwälder Berg- und Alpbergkäse garantieren ein Genusserlebnis zu Salat, Beilage, bei der Grillparade. Stark limitierte Stückzahl. Solange der Vorrat reicht.',
        manufacturer: 'Käsecaspar',
        price: 449
      }
    ],
    basket: []
  },
  mutations: {
    clearCart (state) {
      state.basket = []
    },
    addProductToCart(state, productId) {
      if (!state.basket.includes(productId)) {
        state.basket.push({id: productId, quantity: 1})
      }
    },
    removeProductFromCart(state, productId) {
        state.basket = state.basket.filter(item => item.id !== productId)
    },
    loadProducts (state, products) {
      state.products = products
    },
    loadSettings (state, settings) {
      console.log('set Settings')
     state.settings = settings
    },
    loadCategories (state, categories) {
      console.log('set categories')
      state.categories = categories
    }
  },
  actions: {
    async loadProducts( { commit } ) {
      const products = await fetch(baseUrl + 'products.json' + '?t=' + Date.now(), { mode: 'no-cors' }).then(r => r.json())
      console.log(products)
      commit('loadProducts', products)
    },
    async loadSettings( { commit } ) {
      const settings = await fetch(baseUrl + 'settings.json' + '?t=' + Date.now(), { mode: 'no-cors' }).then(r => r.json())
      console.log(settings)
      commit('loadSettings', settings)
    },
    async loadCategories( { commit } ) {
      const categories = await fetch(baseUrl + 'categories.json' + '?t=' + Date.now(), { mode: 'no-cors' }).then(r => r.json())
      console.log(categories)
      commit('loadCategories', categories)
    },
    addProduct( { commit }, productId) {
      commit('addProductToCart', productId)
    },
    removeProduct ({ commit }, productId) {
      commit('removeProductFromCart', productId)
    },
    clearCart ({ commit }) {
      commit('clearCart')
    },
    async sendRequest ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
        axios.defaults.baseURL = 'https://shop.kaesecaspar.at'
        // axios.defaults.baseURL = 'http://localhost/projects/kaesecaspar'
        commit('x', 'x')
        // return axios.post('/sendform.php', payload)

        axios.post('/sendform.php', payload)
          .then((result) => {
            console.log(result)
            if (result.data.state === 'success') {
              commit('clearCart')
              resolve(result.data)
            } else if (result.data.state === 'error') {
              reject(result.data.error)
            }
          })
          .catch((error) => {
            reject(error.message)
          })
      })
    }
  },
  modules: {
  }
})
