<template>
  <div>
    <div>{{ settings.categories }}</div>
    <template v-for="category in categories">
      <div :key="category">
<!--        <category :title="category.title"></category>-->
        <div class="my-5">
            <v-expansion-panels
                active-class="expanded"
                flat
            >
              <v-expansion-panel style="background-color: transparent"
              >
                <v-expansion-panel-header
                    class="v-btn--outlined rounded pa-2"
                    color="secondary"
                    expand-icon="mdi-information-variant"
                >
                  <div class="text-h5 text-uppercase text-center font-weight-bold">
                    {{ category.title }}
                    <br>
                    <div class="caption">{{ getProductCountByCategory(category.title) }} Produkte</div>

                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="secondary">
                  <hr style="height:1px;border:none;color:#333;background-color:#333;" class="mb-5">
                  <div v-html="category.info">
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide
              v-for="product in getProductsByCategory(category.title)"
              :key="product.id"
          >
            <card-product
                :product="product"
                :active="active"
                :toggle="toggle"
                class="ma-2"
            ></card-product>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>

    </template>
  </div>
</template>

<script>

import CardProduct from "./CardProduct";
import { mapState } from 'vuex'
// import Category from "./Category";

export default {
  name: "Products",
  components: {
    // Category,
    CardProduct
  },
  data: () => ({
    model: null,
    swiperOption: {
      slidesPerView: 5,
      spaceBetween: 5,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      // effect: 'coverflow',
      coverflowEffect: {
        rotate: 20,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows : true
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    }
  }),
  computed: {
    ...mapState({
      settings: state => state.settings,
      products: state => state.products,
      categories: state => state.categories,
      basket: state => state.basket
    })
  },
  methods: {
    getProductsByCategory (category) {
      return this.products.filter(p => p.category === category)
    },
    getProductCountByCategory (category) {
      return this.products.filter(p => p.category === category).length
    }
  }
}
</script>

<style lang="scss">
//@import './base.scss';
.swiper {
  //background: #ccc;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--v-secondary-base);
}

.swiper-pagination {
  position: relative !important;
}

//.swiper-slide {
//  width: 30%;
//}
//.swiper-slide:nth-child(2n) {
//  width: 60%;
//}
//.swiper-slide:nth-child(3n) {
//  width: 40%;
//}

</style>
