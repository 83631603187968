<template>
  <v-app>
<!--    <v-app-bar-->
<!--      app-->
<!--      color="primary"-->
<!--      dark-->
<!--    >-->
<!--      <div class="d-flex align-center">-->
<!--        <v-img-->
<!--          alt="Vuetify Logo"-->
<!--          class="shrink mr-2"-->
<!--          contain-->
<!--          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"-->
<!--          transition="scale-transition"-->
<!--          width="40"-->
<!--        />-->

<!--        <v-img-->
<!--          alt="Vuetify Name"-->
<!--          class="shrink mt-1 hidden-sm-and-down"-->
<!--          contain-->
<!--          min-width="100"-->
<!--          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"-->
<!--          width="100"-->
<!--        />-->
<!--      </div>-->

<!--      <v-spacer></v-spacer>-->

<!--      <v-btn-->
<!--        href="https://github.com/vuetifyjs/vuetify/releases/latest"-->
<!--        target="_blank"-->
<!--        text-->
<!--      >-->
<!--        <span class="mr-2">Latest Release</span>-->
<!--        <v-icon>mdi-open-in-new</v-icon>-->
<!--      </v-btn>-->
<!--    </v-app-bar>-->

    <v-main>
      <router-view/>
    </v-main>


    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>

    <v-footer
        padless
        elevation="2"
    >
      <v-row justify="center" class="text-center" no-gutters>
        <v-col>
          <v-card
              tile
              flat
              color=""
          >
            <v-card-text class="py-2">
              KÄSECASPAR · Caspar Greber, Bergstraße 17, 6850 Dornbirn · T +43 (0) 699 183 84 320 · caspar@kaesecaspar.at
            </v-card-text>

            <v-card-text class="px-4 py-2">
              <span class="mx-2">&copy; {{ new Date().getFullYear() }}</span>
              <span class="mx-2">
                <a href="https://www.kaesecaspar.at/impressum" target="_blank">Impressum</a>
              </span>

            </v-card-text>

            <v-card-text class="caption">
              Made with
              <v-icon small>mdi-heart</v-icon>
              by <a class="" style="" href="https://www.limemotion.com" target="_blank">limemotion</a>
              - (v{{ appVersion }})
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>

</template>

<script>
import strings from '@/lang/strings.json'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
    imprint: strings.imprint,
    privacy: strings.privacy,
    fab: false
  }),
  methods: {
    ...mapActions(['loadProducts', 'loadSettings', 'loadCategories']),
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 300
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
  async mounted () {
    this.loadSettings()
    this.loadCategories()
    this.loadProducts()
  }
};
</script>

<style lang="scss">

#app {
  background-color: var(--v-background-base);
}

</style>
