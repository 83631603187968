<template>
  <v-card

      class="ma-4 text-left"
      elevation="5"
      height="90%"
  >
<!--    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"-->
    <v-img
        :src="'/img/'+product.image"
        height="250px"
        class="white--text align-end text-center"

    >
      <v-card-title style="word-break: break-word;" class="card-title">
        {{ product.title }}
      </v-card-title>
    </v-img>

    <v-card-text class="" v-html="product.teaser"></v-card-text>

    <v-card-subtitle>
      <v-dialog
          v-model="dialog"
          width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              small
              class="text--primary"
              color="secondary"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon left>mdi-arrow-right</v-icon>
            Mehr
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="grey lighten-2 mb-5">
            {{ product.title }}
          </v-card-title>

          <v-card-text v-html="product.description">
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-subtitle>

    <v-card-text>
      <div>
        Preis: {{ product.price }}
      </div>
      <div>
        Stück: {{ product.item }}
      </div>
    </v-card-text>


    <v-card-subtitle>
      <v-checkbox color="secondary text--primary" v-model="saved" @click="test" label="Merken"></v-checkbox>
    </v-card-subtitle>
  </v-card>

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: "CardProduct",
  props: ["active", "toggle", "product"],
  data () {
    return {
      saved: false,
      dialog: false,
    }
  },
  computed: {
    ...mapState({
      products: state => state.products,
      basket: state => state.basket
    })
    // saved () {
    //   const x = this.basket.findIndex(item => item.id === this.product.id)
    //   const inBasket = (x > -1) ? true : false
    //   return inBasket
    // }
  },
  mounted () {
    const x = this.basket.findIndex(item => item.id === this.product.id)
    const inBasket = (x > -1) ? true : false
    console.log('is in basket', this.product.id, inBasket)
    return this.saved = inBasket
  },
  methods: {
    ...mapActions(['addProduct', 'removeProduct']),
    test () {
      console.log(this.product.title, this.saved)
      if (this.saved) {
        this.addProduct(this.product.id)
      } else {
        this.removeProduct(this.product.id)
      }
    },
    isProductInBasket (productId) {
      const x = this.basket.findIndex(item => item.id === productId)
      const inBasket = (x > -1) ? 'true': 'false'
      console.log('is in basket', productId, inBasket)
      return inBasket
    }
  }
}
</script>

<style>
 .card-title {
   text-shadow:0px 0px 6px #000;
 }

 .theme--light.v-card > .v-card__text,
 .theme--light.v-card > .v-card__subtitle {
   color: rgba(0, 0, 0, 1) !important;
 }
</style>
